.alert-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ffccc7;
  background: #fff2f0;
  padding: 15px;

  &-action {
    display: flex;
    margin-left: 10px;
    .cancel-btn {
      margin-left: 10px;
    }
  }
}

.alert-wrapper,
.success {
  border: 1px solid #a7e1c4;
  background: #cfefdf;
}
.info {
  border: 1px solid #87e8de;
  background: #b5f5ec;
}
.error {
  background-color: #f5cfcf;
  border: 1px solid #f7d9d9;
  color: #d9001b;
}
