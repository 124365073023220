.loading-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .back-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0.7;
    z-index: 1;
  }

  span {
    font-size: 40px;

    svg {
      path {
        //fill: #ffffff;
      }
    }
  }
}
