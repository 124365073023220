.add-franchisee-wrapper {
  position: relative;

  .email-container {
    padding: 26px 57px 0px 57px;

    &-error {
      margin-top: -25px;
    }
  }

  .add-franchisee-container {
    display: flex;
    padding: 26px 57px;
    position: relative;

    .panel-left {
      flex: 1;
      padding: 0 10px 0 0;

      .line-item-container {
        margin-bottom: 42px;

        input {
          max-width: 500px;
        }
      }
    }

    .panel-right {
      flex: 1;
      padding: 0 0 0 10px;

      .line-item-container {
        // margin-bottom: 42px;

        input {
          max-width: 500px;
        }
      }

      .group-item-container {
        display: flex;
        margin-bottom: 58px;

        .custom-input-container {
          width: 109px;
          margin-right: 40px;
        }
      }

      .address-item {
        margin-bottom: 35px;
      }
    }
  }

  .additional-action-container {
    margin: 0 57px;

    span {
      margin-left: 10px;
    }
  }

  .action-container {
    display: flex;
    justify-content: flex-end;
    padding: 0 57px 20px 57px;

    button {
      margin: 0 5px;
    }
  }
}
