.onboarding-step-container {
  padding: 100px 24px;
  width: 280px;
  height: 100%;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .onboarding-step-footer {
    .ant-progress-bg {
      background-color: #db47a7;
      height: 11px !important;
    }
  }
}