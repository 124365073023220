.admin-notification-container {
  height: 100%;
  padding: 30px;
  position: relative;

  &-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
  }

  .admin_noti {
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    padding: 20px;
    gap: 20px;

    &-item {
      display: flex;
      justify-content: space-between;

      &-action {
        cursor: pointer;
      }
    }
  }
}
