.pan_doc {
  padding: 20px;
  background-color: #fff;

  &-footer {
    display: flex;
    justify-content: flex-end;
  }

  &-item {
    display: flex;
    justify-content: space-between;

    &-file {
      position: relative;

      input {
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        opacity: 0;
      }
    }
  }
}
