.block_un {
  &-item {
    position: relative;
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    &-sub {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      &-action {
        &-btn {
          cursor: pointer;
        }
      }
    }

    &-wrapper {
      flex: 1;
    }

    &-action {
      position: absolute;
      top: 20px;
      right: 20px;
      &-btn {
        cursor: pointer;
      }
    }

    &-footer {
      padding-top: 10px;
    }
  }

  &-footer {
    &-link {
      font-weight: bolder;
    }
  }
}
