.build-out-info-container {
  margin-bottom: 20px;
  background-color: rgba($color: black, $alpha: 0.05);
  padding: 20px;
  border-radius: 8px;

  &-section {
    margin-bottom: 16px;

    &-construction {
      margin-bottom: 26px;
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 8px;
      color: rgba($color: black, $alpha: 0.5);
    }
  }

  .form-group-five-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
  }
  .form-group-three-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
  .form-group-four-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
  }
  .form-group-two-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
}
