.block_pay {
  &-item {
    position: relative;
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    &-con {
      flex: 1;
    }

    &-action {
      position: absolute;
      top: 20px;
      right: 20px;

      &-btn {
        cursor: pointer;
      }
    }

    &-sub {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      &-wrap {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;
        gap: 10px;
      }

      &-action {
        &-btn {
          cursor: pointer;
        }
      }
    }

    &-footer {
      padding-top: 10px;
    }
  }
}
