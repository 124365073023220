.poc_contacts_item-secondary-details-report {
  display: flex;
  justify-content: space-between;
}

.poc_contacts_item-role {
  display: flex;
  align-items: center;
  font-size: var(--form-font-size);
  font-weight: var(--form-font-weight);
  font-family: var(--form-font-family);
}

.contact_item {
  border: 1px solid #d9d9d9;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 15px;
  position: relative;

  &-action {
    position: absolute;
    top: 10px;
    right: 10px;

    &-edit {
      cursor: pointer;
      margin-right: 10px;
    }

    &-icon {
      cursor: pointer;
    }
  }

  .form-group-three-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .form-group-one-two-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
  }

  .form-group-two-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 10px;
  }

  &-wrapper {
    &-activity {
      &-label {
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: 700;
      }

      &-item {
        display: flex;
        justify-content: space-between;
        margin: 10px 0px;

        &-label {
          font-size: 12px;
          font-weight: 700;
        }
      }
    }

    &-report {
      &-label {
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: 700;
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        &-tool {
          &-label {
            font-size: 12px;
            font-weight: 700;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
