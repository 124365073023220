.zor_overview {
  &-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;

    &-section {
      &-title {
        text-align: center;
        font-weight: bold;
      }
    }
    &-section-label {
      display: flex;
      gap: 20px;
    }
  }

  &-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 40px;

    &-section {
      &-title {
        text-align: center;
        font-weight: bold;
      }
    }
  }

  &-log {
    margin-top: 100px;

    &-wrapper {
      &-title {
        text-align: center;
        font-weight: bold;
      }
    }

    &-analy {
      &-item {
        margin: 20px 0;
        font-size: 16px;
      }
    }
  }
}
