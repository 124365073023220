.stepper-container {

  .stepper-item-container {
    min-height: 100px;
    position: relative;

    .action-bar {
      position: absolute;
      top: 29px;
      width: 1px;
      background: #8C9BA5;
      height: 100%;
      left: 18px;
      z-index: -1;

      &.select {
        background-color: #5B33FF;
      }
    }

    &:last-child {
      min-height: 0;

      .action-bar {
        display: none;
      }
    }

    .step-item-container {
      display: flex;
      align-items: center;
      position: relative;

      .tap-container {
        width: 36px;
        height: 36px;
        border-radius: 20px;
        border: 1.5px solid rgba(140, 155, 165, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        color: rgba(140, 155, 165, 0.6);
        background-color: #fff;

        &.select {
          background-color: #5B33FF;
          color: #fff;
        }
      }

      h3 {
        font-size: 15px;
        margin-bottom: 0;
        margin-left: 50px;
        color: #8C9BA5;
      }
    }

    .sub-options-container {
      margin-left: 55px;

      .option-item-container {
        display: flex;
        align-items: center;
        position: relative;
        margin: 15px 0;

        .sub-tap-container {
          width: 16px;
          height: 16px;
          border-radius: 20px;
          border: 1.5px solid rgba(140, 155, 165, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          color: #fff;
          background-color: #DADADA;

          &.select {
            background-color: #5B33FF;
            color: #fff;
          }

          span {
            font-size: 8px;
          }
        }

        h3 {
          font-size: 12px;
          margin-bottom: 0;
          margin-left: 20px;
          color: #8C9BA5;
        }
      }
    }
  }
}