.admin_franchisee_integration {
  position: relative;

  &-header {
    padding: 31px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 20px;
      color: #000;
      margin-bottom: 0;
    }
  }

  &-main {
    padding: 0 24px;
    &-empty {
      display: flex;
      justify-content: center;
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 40px 0;

    &-add {
    }

    &-update {
      margin-left: 10px;
    }
  }

  &-add {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;

    h4 {
      font-size: 22px;
      margin: 29px 0;
    }

    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;

      &-item {
        width: 210px;
        height: 81px;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
        cursor: pointer;
      }

      &-wrapper {
        padding: 20px 40px;
        margin: 20px;
        box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px 0 #00000014,
          0 9px 28px 8px #0000000d;
      }
    }
  }
}
