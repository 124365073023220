.template_form {
  margin: 10px 0;

  &-header {
    display: flex;
    justify-content: space-between;
    .custom-input-container {
      width: 60%;
    }

    &-action {
      font-size: 20px;
      &-save {
        margin-right: 10px;
      }

      &-cancel {
      }
    }
  }
}
