.zee-ins-info-edit-form > * {
  display: grid;
  grid-template-columns: auto(auto-fill, minmax(300px, 1fr));
  font-family: 'roboto', sans-serif;
}

.zee-ins-info-edit-form {
  margin-top: 30px;
}

.form-group-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.form-trio-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.ant-input, .ant-select-selector {
  height: 40px; /* Match the height */
  padding: 0 11px; /* Ensure consistent padding */
  font-size: 14px; /* Match font size */
  line-height: 40px; /* Vertically align text */
}

.ant-select-selector {
  display: flex;
  align-items: center; /* Center content vertically */
}

.form-group-container > div > .input-title,
.form-single-container > div > .input-title,
.form-trio-container > div > .input-title {
  font-size: var(--form-font-size);
  font-weight: var(--form-font-weight);
  margin-bottom: var(--form-margin-bottom);
}
