.collapse-container {
    margin: 20px 0;

    .collapse-header {
        cursor: pointer;
        padding: 20px;
        background: #FFFFFF;
        border: 1px solid rgba(137, 137, 137, 0.35) ;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.1);
        border-radius: 10px;
        font-weight: bold;
        color: #000C39;
    }
}