.form_liab {
  .form-group-three-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    margin-bottom: 20px;
  }

  .form-group-four-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    margin-bottom: 20px;
  }

  .input-title {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 700;
  }
}
