.onboarding-form_onboard_franchisee {
    position: relative;
  
    &-wrapper {
      h4 {
        font-size: 20px;
      }
  
      &_table {
        &-header {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  