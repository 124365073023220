.quote_action {
  display: flex;
  flex-direction: row-reverse;

  &-btn {
    margin: 10px;
  }

  .action-btn-left {
    .btn-box {
      display: flex;
      background: black;
      margin: 10px;
      .close-btn {
        color: black;
        background: white;
        border-color: black;
      }
      .save-btn {
        background: black;
        border-color: black;
      }
    }
  }
  .action-btn-right {
    .btn-box {
      margin: 10px;
      display: flex;
      background: #5a33ff;
    }
  }
}
