.user_form {
  &-footer {
    padding: 20px 24px 0px 24px;
    border-top: 1px solid #f0f0f0;
    margin-right: -24px;
    margin-left: -24px;
    display: flex;
    justify-content: flex-end;

    button {
      margin: 0 10px;
    }
  }

  &-error {
    margin-bottom: 20px;
  }
}
