.admin_products {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f8fc;
  h5 {
    font-size: 12px;
  }
  p {
    font-size: 12px;
  }
  .products_container {
    margin-top: 170px;
    padding: 30px;
    height: 100%;
    background-image: url("../../../assets/images/product-backgroud.png");
    background-position: left top;
    background-repeat: repeat;
    background-attachment: scroll;
    background-size: 27px 15px;
    .right-content {
      width: 90%;
      float: right;
      .workers-compensation-box {
        min-height: 300px;
        .ant-radio-group {
          width: 100%;
        }
      }
    }
  }
}
