:root {
  --form-header-font-size: 24px;
  --form-header-font-weight: 200;
  --form-font-size: 14px;
  --form-font-weight: normal;
  --form-margin-bottom: 10px;
  --form-font-family: 'roboto', sans-serif;
}
// We define the mixins for two major breakpoints mobile and tablet

// $breakpoint is the variable that can have several values
@mixin devices($breakpoint) {
  //the name of the mixin is devices

  @if $breakpoint == tablet {
    @media only screen and (max-width: 680px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (max-width: 481px) {
      @content;
    }
  }
}
