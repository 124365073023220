.quote_stepper {
  width: 10%;
  float: left;
  .avatar-container {
    margin-bottom: 30px;
    width: 33px;
    height: 32px;
    border-radius: 28px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #5A33FF;
    color: white;
  }
  .avatar-container-completed {
    margin-bottom: 30px;
    width: 33px;
    height: 32px;
    border-radius: 28px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #252426;
    color: white;
  }
  .un-selected-avatar-container {
    margin-bottom: 30px;
    width: 33px;
    height: 32px;
    border-radius: 28px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: black;
  }
  .avatar-title {
    font-size: 14px;
    text-align: center;
    padding-top: 4px;
  }
}
