.additional-info-content {
  padding: 26px 57px;
  position: relative;

  .form-main {
    display: flex;

    .panel-left {
      flex: 1;

      .line-item-container {
        margin-bottom: 42px;

        .custom-input-container {
          max-width: 400px;
        }
      }

      .group-item-container {
        display: flex;
        margin-bottom: 58px;

        .custom-input-container {
          width: 109px;
          margin-right: 40px;
        }
      }

      .button-container {
        button {
          width: 140px;
          height: 40px;
        }
      }
    }

    .panel-right {
      flex: 1;
    }
  }

  .form-footer {
    .button-container {
      margin-top: 60px;

      button {
        width: 140px;
        height: 40px;
      }
    }
  }
}
