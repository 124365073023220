.add-user {
    display: flex;
    justify-content: center;
    padding: 0 20%;
    margin-top: 20px;

    .poc_contacts {
        width: 100%;
    }

    .poc_contacts_item-wrapper {
        display: flex;
        flex-direction: row;
        gap: 10px;

        >div:first-child {
            flex: 1;
        }

        >.poc_contacts_item-wrapper-con {
            flex: 1;
        }
    }
}
