.franchisee_integration {
  &-section {
    margin-bottom: 10px;
    &-label {
      margin-right: 10px;
      font-size: 12px;
      font-weight: 700;
    }
  }
}
