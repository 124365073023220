.onboarding_form_account {
  padding: 26px 57px;
  position: relative;

  &_header {
    margin-bottom: 60px;
    .title {
      font-size: 20px;
      font-weight: bold;
    }
  }

  &_main {
    display: flex;

    .panel-left {
      flex: 1;
      padding: 0 10px 0 0;

      .line-item-container {
        margin-bottom: 42px;

        .custom-input-container {
          max-width: 400px;
        }
      }

      .group-item-container {
        display: flex;
        margin-bottom: 30px;

        .custom-input-container {
          margin-right: 40px;
        }
      }
    }

    .panel-right {
      flex: 1;
      padding: 0 0 0 10px;

      .line-item-container {
        margin-bottom: 42px;

        .custom-input-container {
          max-width: 400px;
        }
      }

      .group-item-container {
        display: flex;
        margin-bottom: 58px;

        .custom-input-container {
          width: 109px;
          margin-right: 40px;
        }
      }
    }
  }

  &_footer {
    display: flex;
    justify-content: flex-end;

    .button-container {
      button {
        width: 140px;
        height: 40px;
      }
    }
  }
}
