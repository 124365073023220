.admin_code {
  padding: 31px 35px;
  position: relative;
  max-height: calc(100vh - 200px);
  overflow: auto;

  &_upload {
    width: 100%;
    max-width: 507px;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px dashed #92aaff;
    border-radius: 10px;
    position: relative;
    margin: 30px auto;
    padding: 20px;

    &-container {
      text-align: center;
    }

    p {
      margin: 20px auto;
      color: #242f57;
      font-size: 14px;
      text-align: center;
    }

    .btn-add-file {
      width: 90px;
      height: 40px;
      border: 2px solid #5a33ff;
      color: #5a33ff;
    }

    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      opacity: 0;
    }

    .file-item {
      display: flex;
      justify-content: space-between;
      background-color: rgba(61, 112, 178, 0.1);
      align-items: center;
      margin: 10px;

      span {
        margin-left: 10px;
        svg {
          fill: #5a33ff;
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: center;
      column-gap: 10px;
    }
  }

  &-content {
    width: 100%;
    overflow: auto;

    &-error {
      color: red;
    }
  }

  &-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    margin-top: 10px;
  }
}
