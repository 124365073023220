.poc-cr-btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 10px;
  gap: 10px;
}

.poc-switches {
  display: flex;
  flex-direction: column;
}

.poc-switches > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.radio-group {
  display: flex;
  gap: 10px;
}

.error-msg {
  color: red;
  font-size: 0.8rem;
}

.poc-switches > div > div > .input-title {
  font-size: var(--form-font-size);
  font-weight: var(--form-font-weight);
  font-family: var(--form-font-family);
  margin-bottom: var(--form-margin-bottom);
}
