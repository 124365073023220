.check_item {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  border-bottom: 1px solid #d9d9d9;

  &-action {
    font-size: 20px;

    &-edit {
      margin: 0 10px;
    }

    &-delete {
    }
  }
}
