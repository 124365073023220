.original_record-form {
  .form-group-two-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .form-group-three-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .custom-input {
    color: #000 !important;
  }

  //   .form-group-three-container {
  //       display: grid;
  //       grid-template-columns: 1fr;
  //   }
}
