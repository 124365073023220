.inst_form {
  &-title {
    font-style: italic;
    font-weight: 700;
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    color: #000;
  }

  &-desc {
    text-align: center;
    font-size: 18px;
    font-weight: 100;
  }

  &-img {
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 100px;
    align-items: center;

    &-main {
      max-width: 300px;
    }

    &-sub {
      position: absolute;
      right: 100px;
      top: 80px;
    }
  }

  &-ti {
    font-size: 24px;
    font-style: italic;
    font-weight: 700;
    color: #000;
    text-align: center;
  }

  &-de {
    text-align: center;
    font-size: 18px;
    font-weight: 100;
  }

  &-footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
