.franchisee-summary-container {
  margin-left: 1px;
  margin-top: 1px;
  overflow: auto;
  position: relative;

  .header-wrapper {
    padding: 20px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-breadcrumb {
      .ant-breadcrumb-link {
        cursor: pointer;
      }
    }

    .action-container {
      display: flex;

      button {
        margin-left: 20px;
      }
    }
  }

  .content-container {
    padding: 0 20px 20px 20px;
    position: relative;

    .card-container {
      margin-bottom: 20px;
      box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.1);
      width: 100%;
      height: 100%;
      background-color: #fff;
      overflow: auto;

      .card-content {
        padding: 31px 24px;

        .content-header {
          h2 {
            font-weight: 700;
            font-size: 20px;
          }
        }
      }
    }
  }
}
