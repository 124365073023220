.block_ve {
  &-item {
    position: relative;
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    &-wrapper {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }

    &-action {
      &-btn {
        cursor: pointer;
      }
    }

    &-sub {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      &-action {
        &-btn {
          cursor: pointer;
        }
      }
    }
  }
  
  &-footer {
    &-link {
      font-weight: bolder;
    }
  }
}
