.dashboard-notification-container {
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: #f7f8fc;
  position: relative;

  .notification-item {
    border: 2px solid #ffccc7;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    background-color: #fff2f0;
    position: relative;

    h4 {
      font-weight: bold;
    }

    p {
      margin: 0;
    }

    &-action {
      cursor: pointer;
    }

    &-time {
      position: absolute;
      top: 7px;
      right: 19px;
      font-size: 12px;
      display: flex;
      align-items: center;

      &-remove {
        color: red;
        cursor: pointer;
        margin-left: 20px;
        font-size: 16px;
      }
    }
  }

  .item-danger {
    border-color: #ffccc7;
    background-color: #fff2f0;
  }

  .item-success {
    border-color: #b7eb8f;
    background-color: #f6ffed;
  }

  .item-info {
    border-color: #91d5ff;
    background-color: #e6f7ff;
  }

  .item-warn {
    border-color: #ffe58f;
    background-color: #fffbe6;
  }

  &-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
  }
}
