.admin_log {
  padding: 31px 35px;
  overflow: auto;
  position: relative;

  &_container {
    &-main {
      display: flex;
  
      &-list {
        flex: 1;
      }
  
      &-detail {
        flex: 1;
        max-width: 100px;
        overflow-y: auto;
        min-width: 400px;
      }
    }
  }
  
}
