.custom-input-container {
  .input-title {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 700;
  }

  .custom-input {
  }
}
