.admin_franchisee_under {
  .form-group-three-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
  }

  .form-group-two-one-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 10px;
  }

  .form-group-two-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
  }

  &-section {
    &-title {
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    &-footer {
      margin-bottom: 10px;
      &-title {
        font-weight: bold;
      }

      &-link {
        margin-right: 20px;
      }
    }
  }

  .section-container {
    margin-top: 40px;
    &-title {
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }

  &-footer {
    margin-bottom: 40px;
  }
}
