.quote_success {
  &-alert {
    color: #63a103;
    border: 1px solid #b0cf80;
    background-color: #eef5e4;
    border-radius: 5px;
    padding: 20px 20px;
    font-size: 13px;
  }

  &-step {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 20px;

    &-item {
      max-width: 160px;
      &-header {
        min-height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        .dot {
          width: 14px;
          height: 14px;
          border-radius: 10px;
        }
      }
      &-content {
        border: 1px solid #aaaaaa;
        border-radius: 10px;
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 188px;
        box-sizing: border-box;

        .anticon {
          font-size: 50px;
          color: #aaaaaa;
        }

        &-title {
          font-size: 14px;
          color: #aaaaaa;
          font-weight: 800;
          font-style: italic;
          margin: 0px;
          margin-top: 10px;
        }

        &-desc {
          font-size: 14px;
          color: #aaaaaa;
          margin: 0px;
          text-align: center;
        }
      }

      .passed &-content {
        border-color: #797979;
      }

      .active &-content {
        background-color: #02a7f0;
        .anticon {
          font-size: 50px;
          color: #fff;
        }

        &-title {
          color: #fff;
        }

        &-desc {
          color: #fff;
        }
      }
    }
  }

  &-title {
    font-size: 48px;
    font-style: italic;
    font-weight: 800;
  }

  &-desc {
    font-size: 18px;
    margin-bottom: 40px;
  }

  &-cal {
    background-color: #02a7f0;
    color: #fff;
    font-size: 18px;
    font-style: italic;
    font-weight: 900;
    padding: 20px;
    border-radius: 10px;
  }

  &-intro {
    margin-top: 80px;
    &-title {
      font-size: 48px;
      font-weight: 800;
      color: #333333;
      font-style: italic;
      line-height: 1.1;
      margin-bottom: 10px;
    }
    &-desc {
      font-size: 18px;
    }

    &-sub {
      font-size: 18px;
      font-weight: 700;
    }
  }
}
