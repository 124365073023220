.onboarding-form_account {
  display: flex;
  padding: 26px 20px;
  position: relative;

  .panel-left {
    flex: 1;
    padding: 0 10px 0 0;

    .form-group-two-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }

    .button-container {
      margin-top: 20px;
    }
  }

  .panel-right {
    flex: 1;
    padding: 0 0 0 10px;

    .image_preview-container {
      border: 1px solid gray;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 10px;
      max-width: 125px;
      max-height: 125px;
      height: 125px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .form_sel {
      margin-top: 20px;

      &-label {
        margin-left: 10px;
      }
    }
  }
}
