.header-container {
  padding: 20px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7ff;
  box-shadow: 3px 1px 3px rgba(0, 0, 0, 0.25);
  margin-bottom: 1px;

  .header-wrapper {
    display: flex;
    align-items: center;

    &_logo {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-version {
        cursor: pointer;
        font-size: 12px;
        margin-top: 6px;
      }
    }

    .title-container {
      margin-left: 120px;
      display: flex;
      align-items: center;
      border: 1px solid #5b55b1;
      padding: 3px 8px;
      border-radius: 4px;

      &-title {
        font-size: 14px;
        // font-family: "OpenSans";
      }

      .action-btn {
        display: flex;
        align-items: center;
        margin-left: 20px;
        font-size: 12px;
        cursor: pointer;
        color: #5b55b1;
        font-weight: bold;

        &-title {
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }
  }

  .logo-image {
    height: 63px;
    max-width: 200px;
  }

  button {
    width: 209px;
    height: 52px;
  }

  .action-container {
    display: flex;
    align-items: center;

    .avatar-link {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #242f57;

      .avatar-container {
        margin-right: 10px;
      }

      span {
        margin-left: 5px;
      }
    }
  }
}

.bg-white {
  background-color: #fff;
}

.hide-menu {
  display: none;
}

.header {
  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;

    &-avatar {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-container {
      display: flex;
      align-items: center;
      border: 1px solid #5b55b1;
      padding: 3px 8px;
      border-radius: 4px;

      &-title {
        font-size: 14px;
        line-height: 1.5;
      }

      .action-btn {
        display: flex;
        align-items: center;
        margin-left: 20px;
        font-size: 12px;
        cursor: pointer;
        color: #5b55b1;
        font-weight: bold;

        &-title {
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }
  }
}
