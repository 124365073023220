.vehicle {
  position: relative;

  &-title-extra {
    display: flex;
    gap: 10px;
  }

  &-con {
    margin-bottom: 20px;
    position: relative;

    &-item {
      margin-bottom: 20px;
      background-color: rgba($color: black, $alpha: 0.05);
      padding: 20px;
      border-radius: 8px;

      &-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }
    }
  }
  &-diff {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    &-right {
      &-label {
        font-weight: bolder;
      }
    }
    &-left {
      &-label {
        font-weight: bolder;
      }
    }
  }
  &-action {
    display: flex;
    align-items: center;

    &-btn {
      margin-right: 10px;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
