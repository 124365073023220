.list-franchisee-container {
  background-color: #f7f8fc;
  height: 100%;
  width: 100%;
  padding: 43px 78px 57px 78px;
  position: relative;

  .list-franchisee-title {
    color: #242F57;
    font-size: 25px;
    margin-bottom: 23px;
    font-weight: 700;
  }

  .list-franchisee-card {
    background: #FFFFFF;
    border: 1px solid #EAEDF7;
    box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.0367952);
    border-radius: 10px;
    width: 100%;
    height: calc(100% - 62px);

    display: flex;
    justify-content: center;
    padding: 25px 40px;
    position: relative;

    .content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 100%;

      h4 {
        font-size: 22px;
        margin: 0 0 17px 0;
        font-weight: 500;
        color: #242F57;
      }

      .table-container {
        width: 100%;
        height: 100%;
        overflow: auto;

        .table-header-container {
          display: flex;
          justify-content: space-between;
          background: #523BB4;
          padding: 14px 23px;
          color: #fff;

          a {
            color: #fff;
          }

          .btns-container {
            a.btn-cancel {
              margin-left: 10px;
            }
          }

        }

        .ant-table-wrapper {
          width: 100%;
        }

        .table-footer-container {

          button {
            width: 133px;
            height: 40px;
          }
        }
      }
    }

    .modal-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .modal-back-container {
        position: absolute;
        background-color: #000;
        opacity: 0.3;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .modal-load-card {
        width: 100%;
        max-width: 440px;
        height: 100%;
        max-height: 602px;
        background-color: #fff;
        box-shadow: 0px 4px 15px rgba(153, 153, 153, 0.25);
        z-index: 1;
        padding: 47px 68px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        p {
          font-size: 18px;
          margin-top: 40px;
          color: #000;
          text-align: center;
        }

        .ant-spin {
          margin-top: 100px;
          span {
            font-size: 100px !important;
          }
        }
      }

      .modal-content-card {
        width: 100%;
        height: 100%;
        background-color: #fff;
        box-shadow: 0px 4px 15px rgba(153, 153, 153, 0.25);
        z-index: 1;
        padding: 0px 0px;
        max-width: 765px;
        max-height: 340px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .modal-content {
          padding: 39px 24px 20px 24px;
          .modal-header-title {
            font-size: 20px;
            color: #242F57;
            margin: 0;
          }

          h1 {
            font-size: 60px;
            color: #242F57;
            margin: 0;
          }

          p {
            font-size: 16px;
            color: #000;
            margin: 0;
          }
        }

        .modal-footer {
          padding: 30px 24px;
          display: flex;
          justify-content: flex-end;
          border-top: 1px solid #eaecf3;

          .cancel-btn {
            width: 140px;
            height: 40px;
          }

          .save-btn {
            width: 140px;
            height: 40px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}