.quote_progress_bar {
  height: 5px;
  position: fixed;
  display: flex;
  width: 100%;
  z-index: 100;

  .progress-bar-status {
    width: 20%;
    background: #dfdfdf;
  }
  .progress-bar-status-active {
    width: 20%;
    background: #5a33ff;
  }
  .progress-bar-status-completed {
    width: 20%;
    background: #080708;
  }
}
