.driver-info-container {
  margin-bottom: 20px;
  background-color: rgba($color: black, $alpha: 0.05);
  padding: 20px;
  border-radius: 8px;

  .form-group-five-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
  }
}

.driver {
  position: relative;

  &-title-extra {
    display: flex;
    gap: 10px;
  }

  &-con {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
    position: relative;

    &-item {
      border: 1px solid #eeeff5;
      border-radius: 20px;
      max-width: 350px;
      padding: 0px 20px;

      .poc_contacts_item {
        margin-bottom: 0px;
      }
    }
  }
  &-diff {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    &-right {
      &-label {
        font-weight: bolder;
      }
    }
    &-left {
      &-label {
        font-weight: bolder;
      }
    }
  }

  &-action {
    display: flex;
    align-items: center;

    &-btn {
      margin-right: 10px;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
