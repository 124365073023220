.block_off {
  &-item {
    position: relative;
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    &-wrap {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr;
      gap: 10px;
    }

    &-footer {
      margin-top: 10px;
    }

    &-action {
      &-btn {
        cursor: pointer;
      }
    }
  }
}
