.signin-page-container {
  width: 100%;
  height: calc(100vh - 20px);

  .form-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .form-wrapper {
      width: 100%;
      max-width: 441px;
      min-height: 602px;
      box-shadow: 1px 1px 4px 2px #afafaf;
      padding: 47px 68px;

      .title-container {
        color: #242f57;
        font-size: 26px;
        margin: 0;
        font-weight: 700;
      }

      .line-container {
        margin-top: 30px;

        .link-container {
          margin-top: 10px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
      }

      .line-container-bottom {
        margin: 30px 0 20px 0;
      }

      button {
        width: 133px;
        height: 40px;
      }

      .form-error-msg {
        color: #b00001;
      }

      .social-login-container {
        margin-top: 74px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .google-btn {
          box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
          padding: 7px 16px;
          min-width: 210px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #6d6e70;
          margin-bottom: 16px;

          img {
            margin-right: 10px;
          }
        }

        .microsoft-btn {
          box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
          padding: 7px 16px;
          min-width: 210px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #6d6e70;
          img {
            margin-right: 10px;
          }
        }
      }

      .btns-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        min-height: 502px;

        &-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: end;
        }

        &-signin {
          margin: 10px 0;
          width: 230px;
          // background-color: #5855b1;
          color: #5855b1;
          border-radius: 8px;
          font-weight: bolder;
          font-style: italic;
          text-transform: uppercase;
          border-color: #5855b1;

          &:hover {
            color: #5855b1;
            border-color: #5855b1;
          }
        }

        &-signin-code {
          margin: 10px 0;
          width: 230px;
          background-color: #5855b1;
          color: #fff;
          border-radius: 8px;
          font-weight: bolder;
          font-style: italic;
          text-transform: uppercase;
          border-color: #5855b1;
        }

        &-signup {
          margin: 30px 0;
          width: 230px;
          background-color: red;
          color: #fff;
          border-radius: 8px;
          font-weight: bolder;
          font-style: italic;
          text-transform: uppercase;
          border-color: red;
        }

        &-footer {
          display: flex;
          flex-direction: column;
          align-items: center;

          &-img {
            width: 33px;
          }

          &-label {
            text-transform: uppercase;
            font-weight: bolder;
            font-style: italic;
          }

          &-link {
            margin-top: 5px;
            &-policy {
              padding: 0px 10px;
              border-right: 1px solid gray;
              color: gray;
              text-decoration: none;
            }

            &-term {
              padding: 0px 10px;
              color: gray;
              text-decoration: none;
            }
          }
        }

        .logo-image {
          width: 175px;
          margin-top: 100px;
        }
        .sub-logo-image {
          width: 175px;
        }
      }
    }
  }
}
