.onboarding-form_create_user {
  position: relative;

  .form-error {
    margin: 0 auto;
    width: fit-content;
    margin-bottom: 20px;
  }

  &-select {
    display: flex !important;
    justify-content: center !important;
    margin: 30px auto !important;
  }

  form {
    width: 40%;
    margin: 0 auto;
  }

  .form-group-two-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
}
