.denied_access {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &-panel {
    margin: 20px;
    box-shadow: inset 0 0 5px grey;
    padding: 20px;
    max-height: fit-content;

    &-title {
      font-size: 20px;
    }

    &-desc {
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}
