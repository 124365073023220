.section {
  margin-top: 25px;
  .location {
    margin-bottom: 20px;
    background: rgba(0, 0, 0, 0.04);
    padding: 20px;
    border-radius: 12px;
  }
}

.worker_comp_preview-detail {
  margin-bottom: 30px !important;
}

.coverage-type-form-group {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 0px;
}

.owner-officer-form-group {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 0px;
}

.policy-level-data-form-group {
  display: grid;
  grid-template-columns: 3.5fr 1fr;
  gap: 10px;

  .input-group {
    display: grid;
    grid-template-columns: 6fr 1fr;
    gap: 5px;

    .switch {
      width: 30px;
      margin-top: 7.5px;
    }
  }
}

.location-detail-form-group {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  gap: 0px;
  margin-top: 20px;
}

.field-tab {
  margin-bottom: 10px !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.form-group-two-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.form-group-three-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.form-group-three-container-md-gap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.form-group-four-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.form-group-five-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}
