.report-item {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.report-item-switch {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.report-item-switch > * {
  width: fit-content;
}

.report-item-switch > .input-title {
  font-size: var(--form-font-size);
  font-weight: var(--form-font-weight);
}

.report-item > div {
  display: flex;
  flex-direction: column;
}

.report-item-btn {
  width: fit-content;
}

.zor-poc-edit {
  overflow: auto;
  height: 75vh;
  font-size: var(--form-font-size);
  font-weight: var(--form-font-weight);
  margin-bottom: var(--form-margin-bottom);
  font-family: var(--form-font-family);
}
