.dashboard_overview {
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: #f7f8fc;
  position: relative;
  overflow: auto;

  &-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &-section {
      padding: 30px;
      &-title {
        font-weight: bold;
        text-align: center;
      }
    }
  }

  &-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    &-section {
      &-title {
        text-align: center;
        font-weight: bold;
      }
    }
  }

  &_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;

    &-title {
      text-align: center;
      font-size: 19px;
      font-weight: 600;
    }

    &-chart {
      display: flex;
      justify-content: center;
      flex: 2;
      padding: 0;
      fill: #fff;
      margin-bottom: 30px;

      .outer-circle {
        transform: translate(200px, 0px) rotate(90deg);
      }
    }

    &-label {
      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        &-circle {
          width: 26px;
          height: 26px;
          border-radius: 20px;
          margin-right: 10px;
          color: #333;
        }

        &-title {
          font-weight: 600;
        }

        .circle_item_coi {
          background-color: #503678;
        }

        .circle_item_verified {
          background-color: #6956a4;
        }

        .circle_item_compliant {
          background-color: #d5288c;
        }
      }
    }

    &-content {
      display: flex;
      height: 100%;
      align-items: center;

      &-line {
        flex: 1;
      }

      &-group {
        display: grid;
        grid-template-columns: 1fr 1fr;

        &-item {
          text-align: center;
          margin-bottom: 30px;

          h4 {
            font-size: 50px;
            font-weight: 900;
            margin-bottom: 0;
            color: #333;
          }

          span {
            font-weight: 600;
            color: #333;
          }

          &-sub-title {
            font-weight: 400;
          }
        }

        .line_item_hide {
          opacity: 0;
        }
      }
    }

    .card-content {
      height: 100%;
    }
  }
}
