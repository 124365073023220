.onboarding-start-container {
  padding: 180px 0 0 180px;
  position: relative;

  img {
    width: 162px;
  }

  h3 {
    margin: 45px 0 0 0;
    font-size: 26px;
  }

  h4 {
    font-size: 21px;
    margin-bottom: 32px;
  }

  button {
    width: 170px;
    height: 52px;
  }
}
