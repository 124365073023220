.zee_overview {
  // width: 100%;
  overflow: auto;
  background-color: #fff;
  margin: 20px;

  &-logo {
    background: #fff;
    padding: 10px;

    &-img {
      max-width: 200px;
      max-height: 80px;
    }
  }
  &-header {
    padding: 20px 20px 20px 20px;
    display: flex;
    justify-content: space-between;
  }

  &-container {
    padding: 0 20px 20px 20px;
    background-color: #fff;
    position: relative;

    .card-container {
      margin-bottom: 20px;
      box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.1);
      width: 100%;
      background-color: #fff;
      overflow: auto;

      .card-content {
        padding: 31px 24px;

        .form-group-container {
          display: grid;
          grid-template-columns: 49% 49%;
          grid-column-gap: 2%;

          .custom-input {
            color: #000;
          }
        }

        .form-group-three-container {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 10px;
        }

        .click-quote-btn {
          width: fit-content;
          border-radius: 20px;
          font-size: 16px;
          font-weight: 700;
          height: 35px;
          svg {
            height: 15px;
            width: 20px;
            margin-right: 7px;
          }
        }
        .insurance-market-place {
          display: contents;
        }

        table {
          thead {
            tr {
              th {
                font-weight: bolder;
                background-color: #f5f7fa;
              }
            }
          }

          tbody {
            tr {
              cursor: pointer;
              td {
                .status-container {
                  display: flex;
                  align-items: center;
                  padding: 5px 20px;
                  border: 1px solid gray;
                  border-radius: 5px;
                  width: fit-content;
                  text-transform: uppercase;

                  &.danger {
                    background-color: #ffebea;
                    border-color: #fcc7c6;
                    color: #f03738;
                  }

                  &.success {
                    background-color: #cfedd8;
                    border-color: #b2e4b9;
                    color: #028701;
                  }

                  &.info {
                    background-color: #fdf1d2;
                    border-color: #f8d677;
                    color: #9b7403;
                  }
                }

                .file-item-container {
                  margin: 5px;
                }
              }
            }
          }
        }
      }
    }

    &-acc {
      display: grid;
      grid-template-columns: 3fr 1fr;
      gap: 20px;

      &-action {
        cursor: pointer;
        font-size: 20px;
      }
    }

    &-underwriting {
      position: relative;
    }
  }

  .toolbar_wrapper {
    display: flex;
    justify-content: end;
    position: relative;

    &-ele {
      margin-left: 10px;

      &-label {
        margin-right: 10px;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
}
