.additional-infor-content {
  padding: 26px 57px;
  position: relative;

  .form-main {
    display: flex;

    .panel-left {
      flex: 1;

      .line-item-container {
        margin-bottom: 42px;

        .custom-input-container {
          max-width: 400px;
        }

        .similar-rate {
          width: fit-content;
          margin-top: 10px;
        }
      }

      .group-item-container {
        display: flex;
        margin-bottom: 58px;

        .custom-input-container {
          width: 109px;
          margin-right: 40px;
        }
      }

      .button-container {
        button {
          width: 140px;
          height: 40px;
        }
      }
    }

    .panel-right {
      flex: 1;

      .similar-rate {
        width: fit-content;
        margin-top: 10px;
      }
    }
  }

  .issue-report-item {
    color: red;
    border: 1px solid red;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin: 10px 0;
    border-radius: 10px;
  }
}
