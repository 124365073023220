.epli-content {
  padding: 26px 57px;
  position: relative;
  overflow: auto;

  .form-header {
    .line-disable {
      margin-bottom: 30px;
      display: flex;
      align-items: center;

      .disable-title {
        margin-left: 10px;
        font-size: 14px;
        color: #000;
      }
    }
  }

  .form-main {
    display: flex;
    position: relative;

    .panel-left {
      flex: 1;

      .radio-container {
        margin-bottom: 60px;
        .radio-title {
          font-size: 12px;
          font-weight: 700;
          margin-bottom: 30px;
        }
      }

      .checkbox-container {
        margin-bottom: 17px;
      }

      .button-container {
        margin-top: 60px;

        button {
          width: 140px;
          height: 40px;
        }
      }

      .broker-report-container {
        margin: 30px 30px 30px 0;
      }
    }

    .panel-right {
      flex: 1;

      .line-item-container {
        margin-bottom: 30px;

        .custom-input-container {
          .input-title {
            margin-bottom: 2px;
          }

          .custom-input {
            max-width: 400px;
          }
        }
      }
    }
  }

  .form-footer {
    .button-container {
      button {
        width: 140px;
        height: 40px;
      }
    }
  }
}
