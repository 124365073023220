.class_code {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 20px;
  gap: 10px;
  margin: 5px 0;

  &-sub {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  &-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &-del {
      cursor: pointer;
      font-size: 20px;
    }
  }
}

.spectrum_code {
  display: grid;
  grid-template-columns: 1fr 3fr 20px;
  gap: 10px;
}
