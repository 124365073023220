.admin_overview {
  height: 100%;
  padding: 30px;
  position: relative;
  overflow: auto;

  &_wrapper {
    display: flex;
    justify-content: space-around;

    &-chart {
      flex: 1;

      &-title {
        text-align: center;
        font-size: 19px;
        font-weight: 600;
        margin-bottom: 30px;
      }
    }

    .card-content {
      height: 100%;
    }
  }

  &_report {
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
  }

  .bar-graphs {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 20px;

    button {
      margin-left: auto; /* Push the button to the right */
    }
  }

  .chart-container {
    text-align: center;
    flex: 1 1 calc(33.333% - 20px); /* Each chart takes up roughly 1/3 of the parent width */
    min-width: 300px; /* Ensure a minimum width for readability */


    p {
      margin: 0 0 10px 0;
      text-align: center;
      font-weight: bold;
    }
  }

  @media (max-width: 900px) {
    .chart-container {
      flex: 1 1 100%; /* Stack charts vertically on smaller screens */
    }
  }
}
