.onboard_insurance_requirement {
  overflow: auto;
  &-header {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 0 0;

    &-action {
      display: flex;
      align-items: center;

      label {
        margin: 0 10px;
      }
    }
  }

  &-container {
    padding: 0px 20px;
  }
}
