.onboarding-form_signin_platform {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    position: relative;
  
    .line-container {
      width: 80%;
    }
  }
  