.admin_log_item {
  &-main {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;

    &-action {
      font-size: 20px;
      &-edit {
        cursor: pointer;
        padding: 5px;
      }

      &-delete {
        cursor: pointer;
        padding: 5px;
        color: red;
      }

      &-download {
        margin: 0 10px;
      }
    }
  }

  &-detail {
    max-height: 400px;
    overflow-y: auto;
  }
}
