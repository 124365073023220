.delete_modal {
    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  
      &_item {
        margin: 5px 0;
      }
    }
    &-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 10px;
  
      button {
        margin: 0 5px;
      }
    }
  }
  