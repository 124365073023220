.doc_con {
  position: relative;

  .icon-remove {
    color: red;
    cursor: pointer;
    margin-right: 10px;
  }

  .icon-reject {
    color: #ff0000;
    cursor: pointer;
  }

  &-title {
    font-size: 20px;
    color: #000;
    font-weight: 700;
  }
}
