.admin_assignments {
  overflow: auto;
  background-color: #fff;
  padding: 20px;
  position: relative;
  margin: 30px;
  min-height: 400px;

  ul {
    list-style: none;
  }
}
