.notification-container {
  margin-right: 30px;
  position: relative;
  cursor: pointer;

  span {
    font-size: 26px;
    color: rgba(64, 81, 137, 0.7);
  }

  .notification-alert {
    position: absolute;
    border-radius: 10px;
    background-color: #d60389;
    top: -7px;
    right: -6px;
    padding: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: #fff;
      font-size: 12px;
    }
  }
}
