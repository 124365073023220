.underwriting_question {
  &-section {
    padding: 31px 24px;
    &-title {
      font-size: 20px;
      color: #000;
      margin-bottom: 0;
    }

    &-main {
      margin: 10px;
      border-top: 1px solid #eeeff5;
      border-bottom: 1px solid #eeeff5;

      &-item {
        display: grid;
        grid-template-columns: 5fr 2fr 120px 20px;
        margin: 20px;
        grid-column-gap: 2%;
        align-items: start;

        &-select {
          float: right;
          width: 80%;
        }

        &-select-option {
          display: grid;
          grid-template-columns: 3fr 1fr;
          margin-top: 8px;

          &-add-btn {
            height: 58px;
            margin-left: 8px;
          }
        }

        &-delete-btn {
          margin-top: 10px;
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: end;

      &-delete {
        margin-right: 10px;
        display: flex;
        align-items: center;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: end;

    &-add {
      margin-right: 10px;
    }
  }
}
