.report_con {
  padding: 0 20px 20px 20px;
  background-color: #f7f7ff;
  position: relative;

  .form-group-container {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }

  .report-header {
    display: flex;
    flex-direction: column;

    .report-logo {
      margin: 20px auto 20px auto;
      max-width: 200px;
    }

    h3 {
      text-align: center;
      font-style: italic;
      font-size: 23px;
      margin-bottom: 40px;
    }

    .report-detail {
      display: flex;
      justify-content: space-between;

      .report-detail-item {
        label {
          font-weight: 700;
        }
      }
    }
  }

  .chip-group-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    margin-top: 40px;
    font-family: "OpenSans";

    .chip-item-container {
      background-color: #292929;
      padding: 10px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h3 {
        color: #fff;
        text-align: center;
      }

      .item-report {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 40px;
        padding: 30px;
        font-weight: 900;
      }
    }
  }

  .content-header {
    h2 {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .content-report {
    width: 100%;
    .chart-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;

      &-item {
        margin-bottom: 20px;
        &-title {
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 20px;
        }
      }
    }
  }

  .detail-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid gray;

    h3 {
      font-weight: 700;
    }

    span {
      font-size: 20px;
    }
  }

  .detail-content {
    padding: 10px 0;
    .issue-item {
      border: 1px solid red;
      border-radius: 10px;
      padding: 10px;
      color: red;
      margin: 3px 0;
    }
  }

  .status-item-Not-Verified {
    color: #000;
    background-color: #c5c5c5;
    padding: 5px 20px;
    border-radius: 5px;
    white-space: nowrap;
    border: 1px solid #c5c5c5;
    text-align: center;
    text-transform: capitalize;
    font-size: 12px;
    font-family: "OpenSans";
  }

  .status-item-Document {
    color: #ffbe00;
    background-color: #ffe5994d;
    padding: 5px 20px;
    border-radius: 5px;
    white-space: nowrap;
    border: 1px solid #f9c224;
    text-align: center;
    text-transform: capitalize;
    font-size: 12px;
    font-family: "OpenSans";
  }

  .status-item-Non-Compliant {
    color: #fff;
    background-color: #f13f7a;
    padding: 5px 20px;
    border-radius: 5px;
    white-space: nowrap;
    border: 1px solid #f13f7a;
    text-align: center;
    text-transform: capitalize;
    font-size: 12px;
    font-family: "OpenSans";
  }

  .status-item-In {
    color: #000;
    border: 1px solid #70f0b8;
    background-color: #70f0b8;
    padding: 5px 20px;
    border-radius: 5px;
    white-space: nowrap;
    text-align: center;
    text-transform: capitalize;
    font-size: 12px;
    font-family: "OpenSans";
  }

  .status-item-Compliant {
    color: #000;
    border: 1px solid #70f0b8;
    background-color: #70f0b8;
    padding: 5px 20px;
    border-radius: 5px;
    white-space: nowrap;
    text-align: center;
    text-transform: capitalize;
    font-size: 12px;
    font-family: "OpenSans";
  }

  table {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }

  &-read {
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .status-item {
        width: 200px;
        text-align: center;
        margin-right: 20px;
      }
    }
  }

  &-desc {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    gap: 20px;

    &-title {
      margin-bottom: 0px;
      white-space: nowrap;
      font-size: 24px;
    }

    p {
      margin-bottom: 0px;
    }
  }
}
