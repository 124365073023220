.upload_form {
  &-title {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  &-header {
    padding: 10px 0px;
    text-align: left;
    margin-bottom: 10px;

  }

  &-footer {
    text-align: right;
    margin: 10px 0 20px 0;
  }
}

.upload-img {
  width: 50px;
  height: auto;
}

.document-type-container {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 250px; // Set desired width

  p {
    margin: 0;
  }
}

.dropdown-button {
  display: flex;
  align-items: center;
  width: 290px;

  .dropdown-text {
    margin-right: 1px;
    width: 320px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .dropdown-text-centered {
    margin-right: 8px;
    width: 300px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .dropdown-separator {
    border-left: 1px solid #d9d9d9;
    height: 30px;
    margin: 0 8px;
  }

  .dropdown-icon {
    margin-left: 5px;
  }
}

.menu-item-label {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.success-message {
  border: 1px solid #b2e4b9;
  background-color: #cfedd8;
  color: #028701;
  font-size: 13px;
  white-space: nowrap;
  padding: 8px;
  border-radius: 5px;
}
