.zor-cr-btns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.activate_btn {
  display: flex;
  gap: 10px;
}
