.upload-franchisee-container {
  padding: 43px 78px;
  width: 100%;
  background-color: #f7f8fc;

  h3 {
    font-size: 25px;
    color: #242f57;
    font-weight: 700;
    border-bottom: 28px;
  }

  &-content {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    button.upload-btn {
      width: 507px;
      height: 52px;
      margin: 28px;
    }

    .upload-container {
      width: 100%;
      max-width: 507px;
      height: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 2px dashed #92aaff;
      border-radius: 10px;
      position: relative;

      p {
        margin: 20px auto;
        color: #242f57;
        font-size: 14px;
        text-align: center;
      }

      .btn-add-file {
        width: 90px;
        height: 40px;
        border: 2px solid #5a33ff;
        color: #5a33ff;
      }

      input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        opacity: 0;
      }

      .file-item {
        display: flex;
        justify-content: space-between;
        background-color: rgba(61, 112, 178, 0.1);
        align-items: center;

        span {
          margin-left: 10px;
          svg {
            fill: #5a33ff;
          }
        }
      }
    }

    .contact-list {
      position: relative;
      width: 100%;

      &-container {
        width: 100%;

        &-content {
          overflow: auto;
        }
      }
      &-footer {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 10px;
        column-gap: 10px;
      }
    }
  }
}
