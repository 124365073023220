.pan_driver {
  padding: 20px;
  background-color: #fff;

  &-info {
    &-title {
      font-size: 20px;
      margin: 0px 0px 10px;
    }

    &-footer {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      gap: 10px
    }
  }
}
