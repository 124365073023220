.consultant_container {
  &-wrapper {
    .form-group-two-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }

  &-empty {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-input {
    color: #000 !important;
  }
}
