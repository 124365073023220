.admin_zorcheck {
  padding: 20px;
  margin: 30px;
  background-color: #fff;

  &-action {
    display: flex;
    justify-content: flex-end;
  }
}
