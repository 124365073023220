.template_create {
  min-width: 700px;

  &-wrapper {
    &-footer {
      display: flex;
      justify-content: end;
      margin-top: 30px;

      &-create {
        margin-right: 10px;
      }
    }
  }
}
