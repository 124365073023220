@import "../../../style.scss";

.franchisor-selection {
  @include devices(mobile) {
    width: 100%;
    .ant-select {
      width: 100% !important;
    }
  }
}

.franchisee-form {
  margin-bottom: 20px;
}

.footer {
  display: block;
  margin-top: 20px;

  .quote-now-btn {
    border-radius: 50px;
    height: 50px;
    width: 250px;
    background-color: #389e0d;
    margin-top: 14px;
    border-color: #73d13d;
    &:hover {
      background-color: #52c41a;
      border-color: #73d13d;
    }
    &:focus {
      background-color: #52c41a;
      border-color: #73d13d;
    }
    font-size: 16px;
  }
}

.session-expiration-alert {
  position: fixed;
  top: 0;
  right: 0;
  margin: 20px;
  z-index: 100000;
}

.questions-section {
  background-color: rgba($color: black, $alpha: 0.05);
  border-radius: 8px;
}

.form-container {
  margin: 20px 0px;
  width: 100%;
}

.alert-confirmation-line {
  margin: 10px 0px 20px 0px;
}

.franchisee-form {
  margin-top: 20px;
}

.img-container {
  margin: 16px 0px;

  &-img {
    max-width: 150px;
    max-height: 150px;
  }

  @include devices(mobile) {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.application-success-title {
  font-size: 38px;
  font-weight: 900;
  font-style: italic;
  margin-top: 20px;
}

.application-success-header {
  font-size: 18px;
  font-weight: 700;
  margin: 6px 0px;
}

.looking-up-franchisee-spin {
  margin: 20px;
}

.input-btn-line {
  display: flex;
  margin-top: 10px;

  @include devices(mobile) {
    display: block;
  }

  .email-input {
    width: 100%;
  }
  .email-action-btn {
    margin-top: 40px;
    margin-left: 10px;
    height: 40px;

    @include devices(mobile) {
      margin: 0px;
      margin-bottom: 10px;
      width: 100%;
    }
  }

  .verification-code-input {
    width: 250px;
    @include devices(mobile) {
      width: 100%;
    }
  }
  .verify-code-btn {
    margin-left: 10px;
    height: 40px;

    @include devices(mobile) {
      margin: 0px;
      margin-bottom: 10px;
      width: 100%;
    }
  }
}
