.hartford_profile {
  .form-group-two-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .form-group-three-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
  .input-title {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 700;

    .input-required {
      color: red;
    }
  }
}
