.affiliates-container {
    margin-bottom: 30px;

    .affiliate-stats,
    .affiliate-franchises {
        margin-top: 30px;
    }

    .stat-card {
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
        text-align: center;
        
        .stat {
            font-size: 3em;
            font-weight: bold;
        }
    }
}