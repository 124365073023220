.splash-screen-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .main-content {
    display: flex;
    align-items: center;
    flex-direction: column;

    span {
      font-size: 40px;

      svg {
        path {
          fill: #5756ab;
        }
      }
    }

    img {
      width: 158px;
      margin-top: 20px;
    }

    h2 {
      font-size: 100px;
      margin-bottom: 0;
      margin-left: 20px;
    }
  }

  .splash-back-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: black;
    opacity: 0.2;
    z-index: 0;
  }

  .splash-loading-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 133px;

      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }
}
