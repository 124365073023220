.dashboard-container {

  .header-container {
    
    .action-container {
        display: none !important;
    }
  }
}

