.zee_sche {
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  min-height: 50vh;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-logo {
      max-width: 150px;
    }
  }

  &-sub-header {
    margin: 20px 0px;
  }

  &-content {
    &-header {
      margin: 40px 0px 20px 0px;
    }
  }
}
