.zor_view_report {
  width: 100%;
  margin-left: 1px;
  margin-top: 1px;
  overflow: auto;
  position: relative;

  .header-wrapper {
    padding: 20px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f7f7ff;

    .ant-breadcrumb {
      .ant-breadcrumb-link {
        cursor: pointer;
      }
    }

    .action-container {
      display: flex;

      button {
        margin-left: 20px;
      }
    }
  }

  &-wrapper {
    position: relative;
  }
}
