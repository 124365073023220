.zor_checklist {
  padding: 31px 24px;
  &-item {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    padding: 10px 0;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;

    &-answ {
      display: flex;
      &-title {
        margin-left: 20px;
      }
    }

    &-ques {
    }
  }

  &-footer {
    display: flex;
    justify-content: end;

    &-add {
      margin-right: 10px;
    }
  }
}
