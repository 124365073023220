.hartford_preview {
  &-detail {
    .form-group-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      margin-bottom: 20px;
    }

    .custom-input {
      color: black !important;
    }
  }
  .quote-access-url-container {
    cursor: pointer;
    margin: 12px 0px 20px 0px;
    border: 1px solid lightgray;
    padding: 8px;
  }
}
