.additional-report {
  &-title {
    font-weight: 100;
    text-transform: capitalize;
    font-size: 21px;
    color: #000;
    font-family: "Roboto", sans-serif;
  }

  &-panel {
    padding: 26px 57px;

    .issue-report-item {
      color: red;
      border: 1px solid red;
      width: -moz-fit-content;
      width: fit-content;
      padding: 5px;
      margin: 10px 0;
      border-radius: 10px;
    }
  }
}
