.report-liability-content {
  padding: 26px 57px;
  position: relative;
  overflow: auto;

  &-analysis {
    margin-bottom: 20px;

    label {
      font-size: 20px;
    }
  }

  .form-header {
    .line-disable {
      margin-bottom: 30px;
      display: flex;
      align-items: center;

      .disable-title {
        margin-left: 10px;
        font-size: 14px;
        color: #000;
      }
    }
  }

  .form-main {
    display: flex;
    position: relative;

    .panel-left {
      flex: 1;

      .radio-container {
        margin-bottom: 60px;

        .radio-title {
          font-size: 12px;
          font-weight: 700;
          margin-bottom: 30px;
        }
      }

      .checkbox-container {
        margin-bottom: 17px;
      }

      .button-container {
        margin-top: 60px;

        button {
          width: 140px;
          height: 40px;
        }
      }

      .alert-container {
        margin-top: 30px;
      }
    }

    .panel-right {
      flex: 1;

      .line-item-container {
        margin-bottom: 30px;

        .custom-input-container {
          .input-title {
            margin-bottom: 2px;
          }

          .custom-input {
            max-width: 400px;
          }
        }
      }
    }
  }

  .form-footer {
    .button-container {
      button {
        width: 140px;
        height: 40px;
      }
    }
  }

  .issue-report-item {
    color: red;
    // border: 1px solid red;
    width: fit-content;
    padding: 5px;
    margin: 10px 0;
    border-radius: 10px;
  }

  &-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
}

.value-green {
  color: green; 
}

.value-red {
  color: red;
}

.toggle-switch {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  span {
    margin-left: 10px;
    font-style: italic
  }
}


.liability-table {
  width: 100%;
  border-collapse: collapse;

  .coverage-lapsed-error {
    color: #ff0000;
    margin-bottom: 20px;

    .ant-alert {
      font-size: 15px;
    }
  }

  .table-header {
    display: flex;
    background-color: #f4f4f4;
    font-weight: bold;
    padding: 5px 0;

    .table-header-field,
    .table-header-fdd,
    .table-header-coverage {
      flex: 1;
      text-align: left;
      padding: 5px;
    }
  }

  .table-row {
    display: flex;
    padding: 5px 0;
    border-bottom: 1px solid #e0e0e0;

    &:last-child {
      border-bottom: none;
    }

    .table-cell {
      flex: 1;
      padding: 4px 8px;
      display: flex;
      align-items: center;

      &.field-title {
        color: #333;
        font-weight: bold;
      }

      &.fdd-requirement {
        color: #555;
      }

      .value-green {
        color: #46b946;
        display: flex;
        align-items: center;

        .anticon {
          margin-right: 4px;
        }
      }

      .value-red {
        color: #ff0000;
        display: flex;
        align-items: center;

        .anticon {
          margin-right: 4px;
        }
      }

      .value-yellow {
        color: #ffcc00;
      }

    }
  }
}
