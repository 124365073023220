.signup-page-container {

  .form-container {
    width: 100%;
    height: 100%;

    .form-wrapper {
      width: 100%;
      height: 100%;
      max-width: 441px;
      min-height: 602px;
      margin: 45px auto auto auto;
      box-shadow: 1px 1px 4px 2px #afafaf;
      padding: 47px 68px;

      .title-container {
        color: #242F57;
        font-size: 26px;
        margin: 0;
        font-weight: 700;
      }

      .line-container {
        margin-top: 30px;

        .link-container {
          margin-top: 10px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }

        button {
          width: 133px;
          height: 40px;
        }
      }

      .footer-sub-container {
        margin: 48px 0;
      }

      .footer-container {
        text-align: center;
      }
    }
  }
}