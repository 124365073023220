.btn_group {
  display: flex;
  border: 1px solid #1677ff;
  border-radius: 7px;
  overflow: hidden;

  &-container {
    padding: 6.4px 15px;
    border-right: 1px solid #1677ff;
    color: #1677ff;
    white-space: nowrap;
    cursor: pointer;

    &-disabled {
      padding: 6.4px 15px;
      border-right: 1px solid #1677ff;
      color: #8d8c8c;
      white-space: nowrap;
      cursor: pointer;
    }

    &:hover {
      background-color: #1677ff;
      color: #fff;
    }

    &:last-child {
      border: none;
    }

    &.btn_selected {
      background-color: #1677ff;
      color: #fff;
    }
  }
}
