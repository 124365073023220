.admin-franchisees-container {
  padding: 31px 35px;
  overflow: auto;
  position: relative;

  .ant-tabs {
    background-color: #fff;
  }

  .card-container {
    .card-content {
      padding: 10px 24px;
    }
  }
}
