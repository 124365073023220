.franchisee-report-container {
  margin-left: 1px;
  margin-top: 1px;
  overflow: auto;
  position: relative;
  max-width: 100%;
  margin: 0 auto;

  .header-wrapper {
    padding: 20px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-breadcrumb {
      .ant-breadcrumb-link {
        cursor: pointer;
      }
    }

    .action-container {
      display: flex;

      button {
        margin-left: 20px;
      }
    }
  }

  .content-container {
    position: relative;
    padding: 0 20px 20px 20px;
    margin-left: -15px;

    .card-container {
      margin-bottom: 20px;
      box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.1);
      height: 60%;
      background-color: #fff;
      overflow: auto;

      .card-content {
        padding: 31px 24px;

        p {
          overflow-wrap: break-word;
        }

        .form-group-container {
          display: grid;
          grid-template-columns: 49% 49%;
          gap: 2%;
        }

        .report-header {
          display: flex;
          flex-direction: column;

          .report-logo {
            margin: 20px auto;
            max-width: 200px;
          }

          h3 {
            text-align: center;
            font-style: italic;
            font-size: 23px;
            margin-bottom: 40px;
          }

          .report-detail {
            display: flex;
            justify-content: space-between;

            .report-detail-item {
              label {
                font-weight: 700;
              }
            }
          }
        }

        .chip-group-container {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 20px;
          margin-top: 40px;

          .chip-item-container {
            background-color: #292929;
            padding: 10px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h3 {
              color: #fff;
            }

            .zee-item-report {
              margin-top: 40px;

              label {
                font-weight: 700;
                color: #fff;
              }

              p {
                color: #fff;
              }
            }
          }
        }

        .content-header {
          h2 {
            font-weight: 700;
          }

          @media (max-width: 768px) {
            margin-top: 0px
          }

          @media (max-width: 992px) {
            margin-top: 0px
          }
        }

        .detail-header {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid gray;

          h3 {
            font-weight: 700;
          }

          span {
            font-size: 20px;
          }

          .policy-num {
            font-size: 20px;
            font-style: italic;
            font-weight: normal;
          }
        }

        .detail-content {
          padding: 10px 0;

          .issue-item {
            border: 1px solid red;
            border-radius: 10px;
            padding: 10px;
            color: red;
            margin: 3px 0;
          }
        }
      }
    }
  }
}

.compliance-card-container {
  margin-bottom: 20px;
  margin-left: 10%;
  box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.1);
  width: 80%;
  height: 100%;
  background-color: #fff;
  overflow: auto;

  .compliance-card-content {
    padding: 31px 24px;

    .compliance-report-header {
      display: flex;
      flex-direction: column;

      .compliance-report-logo {
        margin: 20px auto;
        max-width: 200px;
      }

      h3 {
        text-align: center;
        font-style: italic;
        font-size: 23px;
        margin-bottom: 40px;
      }

      .compliance-report-detail {
        display: flex;
        justify-content: space-between;

        .compliance-report-detail-item {
          label {
            font-weight: 700;
          }
        }
      }
    }
  }
}
