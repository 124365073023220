.equip {
  position: relative;

  &-title-extra {
    display: flex;
    gap: 10px;
  }

  &-con {
    margin-bottom: 20px;
    position: relative;

    &-item {
      width: 100%;
      border: 1px solid #eeeff5;
      border-radius: 20px;
      padding: 20px;

      &-loc {
        margin-bottom: 20px;

        label {
          margin-bottom: 10px;
          font-size: 12px;
          font-weight: 700;
          text-transform: capitalize;
        }

        &-select {
          margin-top: 10px;
        }
      }

      .poc_contacts_item {
        margin-bottom: 0px;
      }
    }
  }
  &-diff {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    &-right {
      &-label {
        font-weight: bolder;
      }
    }
    &-left {
      &-label {
        font-weight: bolder;
      }
    }
  }

  &-action {
    display: flex;
    align-items: center;

    &-btn {
      margin-right: 10px;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
