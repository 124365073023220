.zee_rating {
  padding: 31px 24px;
  &-item {
    display: grid;
    grid-template-columns: 72% 20% 4%;
    grid-column-gap: 2%;
    margin: 20px 0;
    align-items: center;
  }

  &-footer {
    display: flex;
    justify-content: end;

    &-add {
      margin-right: 10px;
    }
  }
}
