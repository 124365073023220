.admin_hartford {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f8fc;

  &-container {
    margin-top: 170px;
    padding: 30px;
    height: calc(100vh - 170px);
    overflow: auto;
    background-image: url("../../../assets/images/product-backgroud.png");
    background-position: left top;
    background-repeat: repeat;
    background-attachment: scroll;
    background-size: 27px 15px;

    .right-content {
      width: 90%;
      float: right;
    }

    &-wrapper {
      min-height: 300px;

      .nacis-code-container {
        display: grid;
        grid-template-columns: 49% 49%;
        grid-gap: 2%;
        gap: 2%;
        .custom-input {
          color: #000;
        }
      }
    }
  }
}
