.carrier_appetite {
  display: grid;
  grid-template-columns: 30% 66%;
  gap: 4%;
  margin-bottom: 20px;

  &-form {
    &-title {
      display: grid;
      grid-template-columns: 1fr 1fr 3fr 20px;
      grid-column-gap: 2%;
    }

    &-value {
      &-item {
        display: grid;
        grid-template-columns: 1fr 1fr 3fr 20px;
        grid-column-gap: 2%;
        align-items: center;
        margin: 5px 0;
      }
    }
  }

  &-footer {
    grid-column: 1 / 3;
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
  }
}
