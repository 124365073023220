.re_cl {
  position: relative;
  &-wrap {
    margin-bottom: 20px;
  }

  &-con {
    display: flex;
    justify-content: space-between;

    &-item {
      text-align: center;

      &-label {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 700;
      }
    }

    &-sub {
      display: flex;
      gap: 10px;
    }
  }

  &-poc {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    &-card {
      border: 1px solid #f0f0f0;
      padding: 10px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      cursor: pointer;

      &-ele {
        label {
          font-weight: bolder;
          margin-bottom: 5px;
        }

        p {
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }
    }

    .active-card {
      border: 3px solid #1677ff;
    }
  }

  &-footer {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
}
