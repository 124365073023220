.epli-content {
  padding: 26px 57px;
  position: relative;

  .form-header {
    .line-disable {
      margin-bottom: 53px;
      display: flex;
      align-items: center;

      .disable-title {
        margin-left: 10px;
        font-size: 14px;
        color: #000;
      }
    }
  }

  .form-main {
    position: relative;
    display: flex;
    margin-bottom: 20px;

    .panel-left {
      flex: 1;

      .radio-container {
        margin-bottom: 60px;

        .ant-radio-group {
          display: flex;
          flex-direction: column;

          label {
            margin-bottom: 10px;
          }
        }
      }

      .checkbox-group-container {
        margin-bottom: 40px;

        .checkbox-group-title {
          font-size: 15px;
          font-weight: 500;
          color: #000c39;
        }

        .checkbox-group-content {
          display: flex;
          flex-wrap: wrap;
          line-height: 50px;

          .ant-checkbox-wrapper {
            flex: 0 0 48%;
            margin-left: 0;
          }
        }
      }

      .checkbox-container {
        margin-bottom: 17px;
      }

      .alert-container {
        margin-top: 30px;
        margin-right: 20px;

        input {
          max-width: 400px;
        }
      }

      .custom-input-container {
        .input-title {
          margin-bottom: 2px;
        }

        .custom-input {
          max-width: 400px;
        }
      }
    }

    .panel-right {
      flex: 1;

      .line-item-container {
        margin-bottom: 30px;

        .custom-input-container {
          .input-title {
            margin-bottom: 2px;
          }

          .custom-input {
            max-width: 400px;
          }
        }
      }
    }
  }

  .form-footer {
    .button-container {
      margin-top: 60px;

      button {
        width: 140px;
        height: 40px;
      }
    }
  }

  .issue-report-item {
    color: red;
    border: 1px solid red;
    width: fit-content;
    padding: 5px;
    margin: 10px 0;
    border-radius: 10px;
  }

  &-score {
    display: flex;
    justify-content: end;
    margin-bottom: 15px;
  }
}
