.release {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 8px;
}

.release_note_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .release_note_header-version,
  .release_note_header-date {
    font-weight: bold;
  }
}

.release_note_content {
  padding-left: 0;

  .release_note_section-title {
    font-weight: bold;
    list-style-type: none; // Removes bullets for section titles only
    margin-top: 10px; // Adds spacing above titles
  }

  .release_note_item {
    display: list-item; // Ensures bullets are displayed
    list-style-type: disc; // Explicitly sets bullet style
    padding-left: 20px; // Indents bullet and text together
  }
}
