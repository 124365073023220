.sync-franchisee-container {
  background-color: #f7f8fc;
  height: 100%;
  width: 100%;
  padding: 43px 78px 57px 78px;

  .sync-franchisee-title {
    color: #242F57;
    font-size: 25px;
    margin-bottom: 23px;
  }

  .sync-franchisee-card {
    background: #FFFFFF;
    border: 1px solid #EAEDF7;
    box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.0367952);
    border-radius: 10px;
    width: 100%;
    height: calc(100% - 62px);

    display: flex;
    justify-content: center;
    align-items: center;

    .content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 159px;
      }

      h4 {
        font-size: 21px;
        margin: 29px 0;
      }

      button {
        width: 133px;
      }

      p {
        font-size: 17px;
        margin: 100px 0 0 0;
      }

      button {
        width: 256px;
        height: 40px;
      }
    }
  }
}