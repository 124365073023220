.merge_zee {
  position: relative;

  &-con {
    display: flex;
    gap: 10px;
    margin-top: 10px;

    &-left {
      overflow: auto;
      flex: 1;
      max-height: 800px;
    }

    &-right {
      overflow: auto;
      flex: 1;
      max-height: 800px;
    }

    &-tool {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }
}
