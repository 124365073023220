.onboarding-form {
  &_select_platform {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;

    h4 {
      font-size: 22px;
      margin: 29px 0;
    }

    .platforms-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;

      .platform-item {
        width: 210px;
        height: 81px;
        background-repeat: no-repeat;
        background-position: 50%;
        margin: 40px;
        cursor: pointer;
        background-size: contain;
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &_btn-ok {
      margin-left: 20px;
    }
  }
}
