.check_form {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  .custom-input-container {
    width: 60%;
  }

  &-action {
    font-size: 20px;
    &-save {
      margin-right: 10px;
    }

    &-cancel {
    }
  }
}
