.admin_franchisee_under-section {
  .form-group-three-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .location-block {
    margin-bottom: 20px;
    background-color: rgba($color: black, $alpha: 0.05);
    padding: 20px;
    border-radius: 8px;
  }

  .form-group-two-one-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 10px;
  }

  .form-group-two-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
  }
}
