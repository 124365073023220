.real {
  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &-action {
      text-align: right;

      &-btn {
        margin: 5px 0px;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
  }
}
