.quote_header {
  margin-top: 5px;
  z-index: 9999;
  position: fixed;
  box-shadow: 0px 1px 5px #99999959;
  width: 100%;
  height: auto;
  display: flex;
  background: white;
  padding: 5px 30px 0px 30px;
  .product-logo-img {
    width: 100px;
  }
  .product-header-logo-box {
    padding: 5px;
    width: 10%;
    height: 100%;
  }
  .product-header-box {
    padding: 5px;
    width: 30%;
    height: 100%;
  }

  h5 {
    font-size: 12px;
  }

  h4 {
    font-size: 12px;
  }

  p {
    font-size: 12px;
  }
}
