.show-hide-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  .show-hide-box {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.custom-input-container {
  position: relative;

  .input-title {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 700;

    .input-sub-title {
      font-weight: 400;
      font-size: 11px;
    }

    .input-required {
      color: red;
    }
  }

  .custom-input {
    display: flex;
  }

  .input-antd {
    padding: 7px 11px;
    border: 1px solid #d9d9d9;
    font-size: 16px;
    width: 100%;
    border-radius: 8px;
    line-height: 1.5;
    outline: none;
    box-sizing: border-box;

    &:hover {
      border-color: #4096ff;
    }
  }

  .custom-input-warning {
    color: red;
    position: absolute;
    right: 16px;
    bottom: 12px;
    font-size: 16px;
  }

  .desc-text {
    font-size: 12px;
    color: #888;
  }

  &.error {
    .custom-input {
      border: 2px solid #e30000;
    }

    .error-log {
      margin: 10px 0px;

      span {
        color: #b00001;
        background-color: #ffebea;
        font-size: 12px;
        padding: 3px 10px;
        border-radius: 30px;
      }
    }
  }
}

.custom-antd-input {
  box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    border-radius: 6px;
    transition: all 0.2s;
    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    outline-color: #1677ff;
    padding: 7px 11px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 8px;
    outline-width: 1px;
}
