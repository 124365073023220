.section_table {
  margin-top: 40px;

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-bottom: 20px;

    &-action {
      display: flex;
      align-items: center;
      &-label {
        font-size: 16px;
        font-weight: bold;
        margin-right: 10px;
      }

      &-span {
        margin-left: 10px;
      }
    }

    &-toolbar {
      &-select {
        margin-right: 10px;
      }
    }
  }
}
