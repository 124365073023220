.block_loc {
  &-item {
    position: relative;
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 20px;
    border-radius: 8px;
    display: flex;

    &-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-right: 50px;

      &-option {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
      }
    }

    &-action {
      position: absolute;
      top: 20px;
      right: 20px;

      &-btn {
        cursor: pointer;
      }
    }
  }

  &-footer {
    &-link {
      font-weight: bolder;
    }
  }
}
