.in_per {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;

  &-account {
    position: relative;

    &-title {
      display: flex;
      justify-content: space-between;
      min-width: 100%;

      &-edit {
        position: absolute;
        right: 20px;
      }
    }

    &-wrapper {
      display: flex;
      gap: 10px;
      justify-content: space-between;

      .activate_btn {
        margin-bottom: 10px;
        margin-left: 10px;
        margin-top: 10px;
        &-label {
          margin-left: 10px;
        }
      }
    }

    &-content {
      .group-item-container {
        .apply-btn {
          margin-top: 41px;
        }
      }

      .image_preview-container {
        border: 1px solid gray;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 10px;
        max-width: 125px;
        max-height: 125px;
        height: 125px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-footer {
        margin-top: 20px;

        &-btn-email {
          margin-left: 10px;
        }
      }
    }
  }

  .group-item-container {
    display: flex;
    gap: 10px;
  }
}
