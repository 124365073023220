.insurance-requirement-container {
  background: #f7f8fc;
  padding: 20px 78px;
  width: 100%;
  overflow: auto;

  .insurance-requirement-title {
    font-size: 25px;
    color: #242f57;
    font-weight: 700;
    margin: 0;
  }

  .popup-active {
    margin: 25px 0;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-left: 6px solid #5b33ff;
    display: flex;
    padding: 10px 30px;

    .info-avatar {
      width: 25px;
      height: 25px;
      background-color: #5b33ff;
      border-radius: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin-right: 14px;
    }

    .popup-title {
      font-size: 14px;
      color: #000;
      margin-right: 8px;
      font-weight: 600;
    }

    .popup-content {
      font-size: 14px;
      color: #000;
      margin-right: 8px;
      font-weight: 400;
    }
  }

  .requirement-main-container {
    box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.0367952);
    border-radius: 10px;
    background-color: #fff;
    position: relative;

    .card-header {
      padding: 0 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .ant-tabs-nav {
        margin: 0;
      }

      .ant-tabs-ink-bar {
        height: 3px !important;
      }

      .ant-tabs-tab {
        padding: 20px;

        .ant-tabs-tab-btn {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }

    .card-content {
      padding: 26px 57px;
    }

    .modal-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .modal-back-container {
        position: absolute;
        background-color: #000;
        opacity: 0.3;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .modal-load-card {
        width: 100%;
        max-width: 440px;
        height: 100%;
        max-height: 602px;
        background-color: #fff;
        box-shadow: 0px 4px 15px rgba(153, 153, 153, 0.25);
        z-index: 1;
        padding: 47px 68px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        p {
          font-size: 18px;
          margin-top: 40px;
          color: #000;
          text-align: center;
        }

        .ant-spin {
          margin-top: 100px;
          span {
            font-size: 100px !important;
          }
        }
      }

      .modal-content-card {
        width: 100%;
        height: 100%;
        background-color: #fff;
        box-shadow: 0px 4px 15px rgba(153, 153, 153, 0.25);
        z-index: 1;
        padding: 0px 0px;
        max-width: 765px;
        max-height: 602px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .modal-header {
          padding: 50px 0 60px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: #3f0176;

          img {
            width: 113px;
            margin-bottom: 32px;
          }

          .modal-header-title {
            font-size: 22px;
            font-weight: 500;
            color: #fff;
            margin-bottom: 26px;
          }

          .modal-header-desc {
            font-size: 14px;
            font-weight: 400;
            color: #fff;
            text-align: center;
            margin-bottom: 0;
          }
        }

        .modal-content {
          padding: 33px 60px 0 60px;
          .modal-header-title {
            font-size: 20px;
            color: #242f57;
            margin: 0;
          }

          .modal-content-title {
            font-size: 20px;
            color: #242f57;
            font-weight: 500;
          }

          ul {
            list-style-type: none;
            padding-left: 0;
            width: 50%;
            margin-bottom: 0;

            li {
              font-size: 14px;
              font-weight: 400;

              &:first-child {
                margin-bottom: 13px;
              }
            }
          }
        }

        .modal-footer {
          padding: 0 24px 30px 24px;
          display: flex;
          justify-content: flex-end;

          .navigate-btn {
            width: 200px;
            height: 40px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
