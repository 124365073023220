.dashboard-container {
  height: 100%;
  overflow: hidden;

  .dashboard-wrapper {
    display: flex;
    height: calc(100% - 100px);
  }

  /* Adjust the affiliates logo */
  .logo-image {
    margin-top: -60px;
    margin-bottom: -70px;
    height: 190px;
  }
}

.affiliates-container {
  width: 100%;
  padding: 31px 35px;
  overflow: auto;
  background-color: #f7f8fc;
  position: relative;

  .breadcrumbs {
    padding: 0px 30px 20px;
  }

  .action-btn-container {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 14px;

    input {
      margin-right: 10px;
      width: 200px;
    }
  }

  .table-header {
    background-color: #f5f7fa;
    padding: 18px 0;
    display: flex;

    .header-item {
      font-size: 14px;
      font-weight: bolder;

      &:nth-child(1) {
        flex: 341;
        padding-left: 50px;
      }

      &:nth-child(2) {
        flex: 217;
      }

      &:nth-child(3) {
        flex: 287;
      }

      &:nth-child(4) {
        flex: 271;
      }
    }
  }

  .expand-container {
    padding: 13px 50px;

    .expand-header {
      display: flex;

      .header-item {
        color: #5b33ff;
        width: 100px;
        font-weight: bolder;
      }
    }

    .expand-content {
      .content-item {
        display: flex;

        .item-status {
          width: 100px;
          padding: 10px 0;

          .icon-tick {
            font-size: 23px;
            svg {
              fill: #028701;
            }
          }

          .icon-dot {
            font-size: 23px;
            svg {
              fill: #f3bb1c;
            }
          }
        }

        .item-date {
          width: 100px;
          padding: 10px 0;
        }

        .item-activity {
          padding: 10px 0;
        }
      }
    }
  }

  table {
    thead {
      tr {
        th {
          font-weight: bolder;
          background-color: #f5f7fa;
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;
        td {
          .status-container {
            display: flex;
            align-items: center;
            padding: 5px 20px;
            border: 1px solid gray;
            border-radius: 5px;
            width: fit-content;

            &.danger {
              background-color: #ffebea;
              border-color: #fcc7c6;
              color: #f03738;
            }

            &.success {
              background-color: #cfedd8;
              border-color: #b2e4b9;
              color: #028701;
            }

            &.info {
              background-color: #fdf1d2;
              border-color: #f8d677;
              color: #9b7403;
            }
          }
        }
      }
    }
  }

  .card-container {
    .card-header {
      .btn-delete {
        margin-left: 10px;
      }
    }
  }
}

.custom-modal_onboard {
  max-width: 1000px !important;
  width: 100% !important;
}

.custom-modal_delete {
  max-width: 400px !important;
  width: 100% !important;
}
