.insurance-bundle-container {
  &-section {
    &-header {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 20px;
      gap: 10px;

      &-label {
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: end;
    margin-top: 20px;

    &-add {
      margin-right: 10px;
    }
  }

  .insurance-bundle-header {
    padding: 31px 24px;
    display: flex;
    justify-content: space-between;

    h3 {
      font-size: 20px;
      color: #000;
      margin-bottom: 0;
    }
  }

  .section-container {
    display: grid;
    grid-template-columns: 66% 33%;
    grid-column-gap: 1%;

    .main-section {
      .form-group-container {
        display: grid;
        grid-template-columns: 49% 49%;
        grid-column-gap: 2%;
        padding: 0 24px;

        .action-container {
          display: flex;
          align-items: flex-end;

          button {
            margin-left: 10px;
            margin-bottom: 12px;
            height: 45px;
          }
        }
      }

      &_line {
        margin: 24px;
      }
    }

    .code-section {
      .section-header {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;

        label {
          // font-size: 12px;
          // font-weight: 700;
        }

        .btn-add {
          border: 1px solid gray;
          border-radius: 16px;
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 900;
          cursor: pointer;
        }
      }

      .line-item-container {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        gap: 12px;
        margin-bottom: 46px;

        .custom-input-container {
          margin-right: 10px;
          flex: 1;
        }

        button {
          width: 26px;
          height: 26px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
        }
      }
    }
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
    button {
      height: 45px;
    }
  }

  .footer-container {
    padding: 24px;
    display: flex;
    justify-content: end;

    button {
      width: 140px;
      height: 40px;
    }
  }
}
