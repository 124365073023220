.card-container {
  box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.1);
  width: 100%;
  max-height: 100%;
  background-color: #fff;
  overflow: auto;
  margin-bottom: 40px;

  .card-header {
    padding: 31px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eeeff5;

    &-title {
      display: flex;
      gap: 20px;
      align-items: center;
      flex-direction: column;
      align-items: flex-start;

      h3 {
        font-size: 21px;
        color: #000;
        margin: 0;
        font-weight: 100;
        text-transform: capitalize;
        font-family: "Roboto", sans-serif;
      }
    }

    &-title > img {
      min-width: 5rem;
      height: 5rem;
    }
  }

  .card-content {
    padding: 31px 24px;

    .ant-table-content {
      overflow: auto;
    }
  }
}
