.insurance-upload-container {
  background-color: #f7f8fc;
  width: 100%;
  height: 100%;
  position: relative;

  .card {
    box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.1);
    background-color: #fff;
    min-width: 600px;

    .card-header {
      padding: 30px;
      border-bottom: 1px solid #e0e0e0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        font-size: 20px;
        color: #000;
        margin-bottom: 0;
      }

      &-action {
      }
    }

    .card-content {
      padding: 30px;

      .file-list-container {
        display: flex;
        flex-wrap: wrap;
        .file-item {
          border: 1px solid #92aaff;
          padding: 5px 10px;
          border-radius: 33px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 5px;

          .title-text {
            margin-right: 15px;
          }
        }
      }

      .line-item-container {
        margin-bottom: 20px;
      }

      .upload-container {
        width: 100%;
        height: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 2px dashed #92aaff;
        border-radius: 10px;
        position: relative;
        padding: 30px;

        p {
          margin: 20px auto;
          color: #242f57;
          font-size: 14px;
          text-align: center;
        }

        .btn-add-file {
          width: 90px;
          height: 40px;
          border: 2px solid #5a33ff;
          color: #5a33ff;
        }

        input {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          opacity: 0;
        }

        .file-item {
          display: flex;
          justify-content: space-between;
          background-color: rgba(61, 112, 178, 0.1);
          align-items: center;

          span {
            margin-left: 10px;
            svg {
              fill: #5a33ff;
            }
          }
        }
      }

      .success-container {
        display: flex;
        justify-content: center;
        min-height: 70px;
        align-items: center;
        font-size: 30px;

        .icon-tick {
          font-size: 30px;
          margin-right: 10px;
          svg {
            fill: #028701;
          }
        }
      }

      .error-container {
        display: flex;
        justify-content: center;
        min-height: 70px;
        align-items: center;
        flex-direction: column;

        &-wrapper {
          font-size: 30px;

          .icon-error {
            font-size: 30px;
            margin-right: 10px;
            svg {
              fill: #aa0000;
            }
          }
        }
      }
    }

    .card-footer {
      padding: 0 30px 30px 30px;

      &-action {
        display: flex;
        justify-content: center;
        button {
          margin-right: 10px;
        }
      }
    }
  }
}
