@import "../../style.scss";

.universal-quote-page {
  width: 100%;
  display: flex;
  justify-content: center;
}

.universal-quote-container {
  width: 80%;
  margin: 45px 0px;
  justify-content: center;

  @include devices(mobile) {
    width: 100%;
    margin: 0px;
    padding: 20px;
  }

  &-tip {
    display: flex;
    align-items: center;
    border: 1px solid #000;
    padding: 20px;
    border-radius: 10px;

    &-img {
      width: 24px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  &-title {
    margin-top: 20px;
  }

  .form-group-two-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    @include devices(mobile) {
      grid-template-columns: 1fr;
    }
  }

  .form-group-three-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    @include devices(mobile) {
      grid-template-columns: 1fr;
    }
  }

  .form-group-four-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;

    @include devices(mobile) {
      grid-template-columns: 1fr;
    }
  }

  .form-group-five-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;

    @include devices(mobile) {
      grid-template-columns: 1fr;
    }
  }

  .show-form-toggle-container {
    margin-bottom: 20px;
  }
}

.error-log {
  margin-bottom: 15px;

  span {
    color: #b00001;
    background-color: #ffebea;
    font-size: 12px;
    padding: 3px 10px;
    border-radius: 30px;
  }
}
