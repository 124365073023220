.collapse-section-container {
  &-header {
    display: flex;
    justify-content: space-between;

    &-right {
      display: flex;
      align-items: center;

      &-contact {
        margin-right: 10px;

        span {
          margin-left: 10px;
        }
      }

      &-alert {
        margin-right: 20px;
        font-size: 30px;
      }
    }

    h3 {
      font-weight: 100;
      font-size: 21px;
      color: #000;
      text-transform: capitalize;
      font-family: "Roboto", sans-serif;
    }
  }
}
