.admin_insurance_requirement {
  &-header {
    display: flex;
    justify-content: flex-end;

    &-action {
      display: flex;
      align-items: center;

      label {
        margin: 0 10px;
      }
    }
  }
}

.modal_template_select {
  &-item {
    box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.1);
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      font-weight: bold;
    }
  }
}

.modal_template_create {
  &-footer {
    display: flex;
    justify-content: end;
  }
}
