.product-item-container {
  box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.1);
  width: 100%;
  height: 80px;
  background-color: #fff;
  overflow: auto;
  margin-bottom: 15px;

  .card-header {
    padding: 24px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 20px;
      color: #000;
      margin-bottom: 0;
    }
  }

  .card-content {
    padding: 31px 24px;

    .ant-table-content {
      overflow: auto;
    }
  }

  .avatar-container {
    width: 33px;
    height: 32px;
    border-radius: 28px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .avatar-title {
    font-size: 20px;
    text-align: center;
  }
  .product-item-title {
    width: 90%
  }
  .product-logo-img {
    height: 25px;
    width: 55px;
  }
}
