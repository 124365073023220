.block_dri {
  width: 100%;
  &-item {
    position: relative;
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    &-action {
      &-btn {
        cursor: pointer;
      }
    }
  }

  &-footer {
    &-link {
      font-weight: bolder;
    }
  }
}
