.admin-container {
  height: 100%;

  .admin-wrapper {
    height: calc(100% - 100px);
    display: grid;
    grid-template-columns: 250px 1fr;
  }
}

//
.logo-container {
  padding: 20px;
}
