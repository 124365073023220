.admin_appetite {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f8fc;
  h5 {
    font-size: 12px;
  }
  p {
    font-size: 12px;
  }
  .appetite_container {
    height: calc(100vh - 170px);
    overflow: auto;
    margin-top: 170px;
    padding: 30px;
    background-image: url("../../../assets/images/product-backgroud.png");
    background-position: left top;
    background-repeat: repeat;
    background-attachment: scroll;
    background-size: 27px 15px;
    .right-content {
      width: 90%;
      float: right;
      .workers-compensation-box {
        min-height: 300px;

        .nacis-code-container {
          display: grid;
          grid-template-columns: 49% 49%;
          gap: 2%;
        }

        .custom-input {
          color: #000;
        }

        .form-group-container-insurance {
          display: grid;
          grid-template-columns: 25% 25% 46%;
          grid-column-gap: 2%;
        }
        .form-group-container {
          display: grid;
          grid-template-columns: 49% 49%;
          grid-column-gap: 2%;
        }

        .form-group-three-container {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-column-gap: 10px;
        }

        .custom-input-container .custom-input {
          background: #f3f3f3;
        }

        .ant-table-content {
          overflow: auto;
        }
        .ant-checkbox-wrapper + .ant-checkbox-wrapper {
          margin-left: 0px;
        }
        .item-card-left {
          width: 50%;
          float: left;
          display: grid;
        }
        .item-card-right {
          width: 50%;
          float: right;
        }
      }
    }
  }
}
