.sidebar {
  &-logo {
    padding: 20px 20px 0px 20px;

    &-version {
      text-align: center;

      &-label {
        cursor: pointer;
        font-size: 12px;
        margin-top: 6px;
        text-align: center;
      }
    }
  }

  &-sublogo {
    padding: 0px 20px 20px 20px;
    display: flex;
    justify-content: center;

    &-img {
      max-width: 100%;
      max-height: 100px;
    }
  }
}
