.form-group-two-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.effective-expire-form {
  margin-bottom: 20px;
}

.input-title {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 700;

  .input-required {
    color: red;
  }
}
