.location {
  &-title {
    font-weight: 100;
    text-transform: capitalize;
    font-size: 21px;
    color: #000;
    font-family: "Roboto", sans-serif;
  }
  &_form {
    &-item {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr;
      gap: 10px;

      .custom-input-container {
        .input_visible {
          color: #000;
        }
      }
    }

    &-error {
      color: #f13f7a;
    }

    &-success {
      color: #70f0b8;
    }
  }
}
