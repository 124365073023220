.question-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.save-button {
  margin-top: 4px;
  float: right;
}
