.admin_eligibility {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f8fc;

  h5 {
    font-size: 12px;
  }
  p {
    font-size: 12px;
  }
  &_container {
    margin-top: 170px;
    padding: 30px;
    height: 100%;

    &-content {
      width: 90%;
      float: right;
      overflow: auto;
      height: calc(100vh - 210px);

      &-box {
        min-height: 300px;

        .admin_eligibility {
          &-card {
            padding: 20px;
            box-shadow: 0px 1px 5px #99999959;
            margin: 20px 0;

            &-header {
              &-title {
                font-size: 20px;
                font-weight: bold;
              }
            }

            &-content {
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-gap: 10px;

              .line-item-container {
                margin-bottom: 20px;

                .select-box {
                  &-title {
                    margin-bottom: 10px;
                    font-size: 12px;
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
