.poc_contacts {
  position: relative;

  &-footer {
    display: flex;
    justify-content: flex-end;
  }

  &_item {
    position: relative;
    border-bottom: 1px solid #e5e5e5;

    &-role {
      font-family: var(--form-font-family);
      padding-left: 20px;
    }

    &-action-btns {
      display: flex;
      gap: 10px;
      align-items: center;

      > div {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    &-avatar {
      margin: 0 15px 0 0;
    }

    &-fullname {
      color: #1478FF;
    }

    &-secondary-details {
      display: flex;
      gap: 15px;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    &-switches {
      display: flex;
      flex-direction: column;
      gap: 10px;

      p {
        margin: 0;
      }
    }

    &-primary-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-primary-details-upper {
      display: flex;
      font-size: 1.2rem;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;

      &-con {
        &-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          margin-top: 10px;

          &-title {
            font-size: 12px;
            font-weight: 700;
          }
        }
      }
    }

    &-option {
      .custom-input-container {
        margin-left: 10px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .poc_contacts {
    &_item {
      &-primary-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      &-secondary-details {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: space-between;
      }

      &-switches {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
