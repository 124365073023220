.zees {
  width: 100%;
  padding: 31px 35px;
  overflow: auto;
  position: relative;

  &-con {
    margin-top: 20px;

    .table-header {
      background-color: #f5f7fa;
      padding: 18px 0;
      display: flex;

      .header-item {
        font-size: 14px;
        font-weight: bolder;

        &:nth-child(1) {
          flex: 341;
          padding-left: 50px;
        }

        &:nth-child(2) {
          flex: 217;
        }

        &:nth-child(3) {
          flex: 287;
        }

        &:nth-child(4) {
          flex: 271;
        }
      }
    }

    .expand-container {
      padding: 13px 50px;

      .expand-header {
        display: flex;

        .header-item {
          color: #5b33ff;
          width: 100px;
          font-weight: bolder;
        }
      }

      .expand-content {
        .content-item {
          display: flex;

          .item-status {
            width: 100px;
            padding: 10px 0;

            .icon-tick {
              font-size: 23px;
              svg {
                fill: #028701;
              }
            }

            .icon-dot {
              font-size: 23px;
              svg {
                fill: #f3bb1c;
              }
            }
          }

          .item-date {
            width: 100px;
            padding: 10px 0;
          }

          .item-activity {
            padding: 10px 0;
          }
        }
      }
    }

    table {
      thead {
        tr {
          th {
            font-weight: bolder;
            background-color: #f5f7fa;
          }
        }
      }

      tbody {
        tr {
          td {
            .status-container {
              display: flex;
              align-items: center;
              padding: 5px 20px;
              border: 1px solid gray;
              border-radius: 5px;
              width: fit-content;

              &.danger {
                background-color: #ffebea;
                border-color: #fcc7c6;
                color: #f03738;
              }

              &.success {
                background-color: #cfedd8;
                border-color: #b2e4b9;
                color: #028701;
              }

              &.info {
                background-color: #fdf1d2;
                border-color: #f8d677;
                color: #9b7403;
              }
            }
          }
        }
      }
    }
  }

  &-action {
    display: flex;
    gap: 10px;
  }

  &_filter {
    display: flex;
    justify-content: space-between;

    &_item {
      margin-left: 20px;
      &-label {
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: 700;
      }
    }

    &-action {
      display: flex;
    }
  }
}
