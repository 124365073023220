.board-platforms-container {
  background-color: #f7f8fc;
  height: 100%;
  width: 100%;
  padding: 43px 78px 57px 78px;

  .board-platforms-title {
    color: #242f57;
    font-size: 25px;
    margin-bottom: 23px;
  }

  .board-platforms-card {
    background: #ffffff;
    border: 1px solid #eaedf7;
    box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.0367952);
    border-radius: 10px;
    width: 100%;
    height: calc(100% - 62px);

    display: flex;
    justify-content: center;

    .content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 100%;

      h4 {
        font-size: 22px;
        margin: 29px 0;
      }

      .platforms-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;

        .platform-item {
          width: 210px;
          height: 81px;
          background-repeat: no-repeat;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
          background-position: center;
          background-size: contain;
          margin: 40px;
          cursor: pointer;
        }
      }

      .modal-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .modal-back-container {
          position: absolute;
          background-color: #000;
          opacity: 0.3;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        .modal-load-card {
          width: 100%;
          max-width: 440px;
          height: 100%;
          max-height: 602px;
          background-color: #fff;
          box-shadow: 0px 4px 15px rgba(153, 153, 153, 0.25);
          z-index: 1;
          padding: 47px 68px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;

          p {
            font-size: 18px;
            margin-top: 40px;
            color: #000;
            text-align: center;
          }

          .ant-spin {
            margin-top: 100px;
            span {
              font-size: 100px !important;
            }
          }
        }

        .modal-content-card {
          width: 100%;
          max-width: 440px;
          height: 100%;
          max-height: 602px;
          background-color: #fff;
          box-shadow: 0px 4px 15px rgba(153, 153, 153, 0.25);
          z-index: 1;
          padding: 47px 68px;
          overflow: auto;

          .modal-header-title {
            font-size: 26px;
            color: #242f57;
          }

          .line-container {
            margin-top: 30px;

            .save-btn {
              width: 133px;
              margin-right: 10px;
              height: 40px;
            }

            .cancel-btn {
              width: 133px;
              height: 40px;
            }
          }
        }
      }
    }
  }
}
