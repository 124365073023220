.admin_franchisee_integration_detail {
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  padding: 20px;
  margin: 10px 0;
  position: relative;

  &-preview {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      font-size: 18px;
      font-weight: bold;
    }
  }

  &-form {
    &-header {
      cursor: pointer;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-title {
        font-size: 18px;
        font-weight: bold;
      }
    }

    &-main {
      &-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        margin-bottom: 20px;
      }

      &-footer {
        display: flex;
        justify-content: flex-end;

        button {
          margin: 0 5px;
        }
      }
    }
  }

  &-modal {
    min-width: 678px;
    position: relative;

    &-wrapper {
      &_header {
        display: flex;
        justify-content: flex-end;

        .btn-cancel {
          margin-left: 10px;
        }
      }
    }
  }

  .is-platform-toggle-container {
    margin-right: 20px;
    width: 80px;
  }

  .franchisor-selection-container {
    display: flex;
    width: 100%;
  }
}
