.admin_report {
  padding: 31px 35px;
  overflow: auto;
  position: relative;

  &-action {
    display: flex;
    justify-content: start;
  }

  &-content {
    margin-top: 20px;

    table {
      thead {
      }

      tbody {
        tr {
          cursor: pointer;
          td {
            .status-container {
              display: flex;
              align-items: center;
              padding: 5px 20px;
              border: 1px solid gray;
              border-radius: 5px;
              width: fit-content;

              &.danger {
                background-color: #ffebea;
                border-color: #fcc7c6;
                color: #f03738;
              }

              &.success {
                background-color: #cfedd8;
                border-color: #b2e4b9;
                color: #028701;
              }

              &.info {
                background-color: #fdf1d2;
                border-color: #f8d677;
                color: #9b7403;
              }
            }
          }
        }
      }
    }
  }
}
