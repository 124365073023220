.admin_setting {
  padding: 31px 35px !important;
  height: 100%;
  overflow: auto !important;

  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }

  .ant-tabs-content-holder {
    border-bottom: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.1);
  }
}
